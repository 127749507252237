import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import React from "react";
import './BankHeader.styles.scss';

const BankHeader = ({
  bankName = 'Sunshine City Bank',
  highlightedMessage = '',
  message = '',
}) => {
  return (
      <div className="bank-header-container">
        <div className='bank-header'>
            <SunIcon className='bank-header-icon'/>
            <p className='bank-header-title'>{bankName}</p>
        </div>
        <div className='bank-header-message-container'>
            <p className='bank-header-message-text--highlighted'>{highlightedMessage}</p>
            <p className='bank-header-message-text'>{message}</p>
        </div>
      </div>
  );
}

export default BankHeader;
