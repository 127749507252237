import React from 'react';
import { Card, CardBody } from 'reactstrap';
import './BalanceCard.styles.scss';

const BalanceCard = ({
    title = '',
    detail = '',
    amount = 0,
    icon = '',
    color = 'grey',
}) => {
  return (
    <Card className='balance-detail-card'>
        <CardBody>
            <div className='balance-detail-card-content'>
                <div>
                    <p className='balance-detail-card-title'>{title}</p>
                    <p className='balance-detail-card-info'>{detail}</p>
                </div>
                {!!icon && (<i className={`now-ui-icons ${icon} card-detail-icon`}></i>)}
            </div>
            <div className={`balance-detail-card-data-container balance-detail-card-data-container--${color}`}>
                <p className='balance-detail-card-data'>${amount}</p>
            </div>
        </CardBody>
    </Card>
  );
}

export default BalanceCard;
