import React from 'react';
import BankLoanSection from './sections/BankLoanSection';
import ExampleContainer from 'components/ExampleContainer';
import './FixedTermLoan.styles.scss';

const FixedTermLoan = () => (
  <ExampleContainer >
    <BankLoanSection />
  </ExampleContainer>
);

export default FixedTermLoan;
