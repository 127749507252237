import React from 'react';

const BankAccountBalanceOption = ({ name = '', isSelected = false }) => (
  <div className={`account-menu-options-item ${isSelected && 'account-menu-options-item--selected'}`}>
        <div className={`account-menu-options-item-${isSelected ? 'highlight' : 'selectable'}`} />
        <p>{name}</p>
  </div>
);

export default BankAccountBalanceOption;
