import React from 'react';
import BankAccountDetail from './sections/BankAccountDetail';
import ExampleContainer from 'components/ExampleContainer';

const FeaturedCreditProduct = () => (
  <ExampleContainer>
    <BankAccountDetail />
  </ExampleContainer>
);

export default FeaturedCreditProduct;
