import * as dayjs from 'dayjs';

export const getPreviousDateByDays = (previousDays = 0) => {
    const today = new Date();
    const pastDate = new Date();
    return new Date(pastDate.setDate(today.getDate() - previousDays)).toISOString().slice(0, 10);
}

export const getLastMonths = ({ numberOfMonths = 6 }) => {
    return [...Array(numberOfMonths)].map((_, index) => {
        return dayjs().subtract(numberOfMonths - index,'months').format('MMM');
    })
}