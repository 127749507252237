import BankAccountMenu from "components/BankAccountMenu";
import BankHeader from "components/BankHeader";
import CodeFooter from "components/CodeFooter";
import RoundedSelector from "components/RoundedSelector";
import { SAVVI_STEM } from "config";
import BalanceCard from "examples/featured-credit-product/components/BalanceCard";
import React, { useState, useEffect, useImperativeHandle } from "react";
import { Card, CardBody } from "reactstrap";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { getRandomLastOperations } from "utils/bank";
import { getLastMonths } from "utils/dates";
import { getRandomAmount, getRandomAmountMultipleOf } from "utils/random";
import { notifyInfo } from "utils/ui";
import { notifySuccess } from "utils/ui";
import "./BankAccountDetail.styles.scss";

const BankAccountDetail = React.forwardRef(({}, ref) => {
  // Influencers
  const [balanceAmount, setBalanceAmount] = useState(getRandomAmount({}));
  const [savingsAmount, setSavingsAmount] = useState(getRandomAmount({}));
  const [loanAmount, setLoanAmount] = useState(getRandomAmountMultipleOf({}));
  const [lastThreeOperations, setLastThreeOperations] = useState(
    getRandomLastOperations()
  );
  const [randomChartData, setRandomChartData] = useState(
    getLastMonths({}).map((month) => ({
      name: month,
      balance: getRandomAmount({ min: 1000, max: 4000 }),
    }))
  );

  // Decision
  const [suggestedProduct, setSuggestedProduct] = useState(null);
  const [suggestedProductResponse, setSuggestedProductResponse] = useState({});
  const [decisionResultData, setDecisionResultData] = useState({});

  const updateWithRandomData = () => {
    setSuggestedProduct(null);
    setBalanceAmount(getRandomAmount({}));
    setSavingsAmount(getRandomAmount({}));
    setLoanAmount(getRandomAmountMultipleOf({}));
    setLastThreeOperations(getRandomLastOperations());
    setDecisionResultData({});
    setRandomChartData(
      getLastMonths({}).map((month) => ({
        name: month,
        balance: getRandomAmount({ min: 1000, max: 4000 }),
      }))
    );
  };

  useImperativeHandle(ref, () => ({
    reloadDemoData() {
      updateWithRandomData();
    },
  }));

  const decideData = {
    bankAccountBalance: balanceAmount,
    loanAmount: loanAmount,
    bankSavingsAccountBalance: savingsAmount,
    mostRecentTransaction1: lastThreeOperations[0]?.description,
    mostRecentTransaction2: lastThreeOperations[1]?.description,
    mostRecentTransaction3: lastThreeOperations[2]?.description,
  };

  const handleDismissProductSuggestion = async () => {
    const learnMoreData = {
      data: {
        clickLearnMore: 0,
      },
      decisionUsedLabel: suggestedProduct?.label,
      decisionUsedId: suggestedProduct?.id,
      stemEventUpdateRule: "overwrite",
    };

    setDecisionResultData(learnMoreData);
    const learnMoreResponse = await window?.SAVVI_AI[
      SAVVI_STEM.FEATURED_CREDIT_PRODUCT.name
    ].results(learnMoreData);
    if (learnMoreResponse?.success) {
      notifySuccess(`Thanks for your feedback. We are not going to recommend ${suggestedProduct.label} again.`);
    }
  };

  const getSuggestedCreditProduct = async () => {
    if (
      !!!window?.SAVVI_AI ||
      !!!window?.SAVVI_AI[SAVVI_STEM.FEATURED_CREDIT_PRODUCT.name]
    ) {
      notifyInfo("We could not get your suggested credit product. Try again later.");
      return;
    }

    const decisionResponse = await window?.SAVVI_AI[
      SAVVI_STEM.FEATURED_CREDIT_PRODUCT.name
    ].decide({
      data: decideData,
    });

    if (!!decisionResponse?.decision?.length) {
      setSuggestedProduct(decisionResponse.decision[0]);
      setSuggestedProductResponse(decisionResponse);
    } else {
      setSuggestedProduct({});
      setSuggestedProductResponse({});
    }
  };

  const decideLearnMoreProduct = async () => {
    const learnMoreData = {
      data: {
        clickLearnMore: 1,
      },
      decisionUsedLabel: suggestedProduct?.label,
      decisionUsedId: suggestedProduct?.id,
      stemEventUpdateRule: "overwrite",
    };

    setDecisionResultData(learnMoreData);
    const learnMoreResponse = await window?.SAVVI_AI[
      SAVVI_STEM.FEATURED_CREDIT_PRODUCT.name
    ].results(learnMoreData);

    if (learnMoreResponse?.success) {
      notifySuccess(`${suggestedProduct.label} information successfully requested.`);
    }
  };

  useEffect(() => {
    getSuggestedCreditProduct();
  }, [balanceAmount]);

  return (
    <>
      <div className="account-detail-container">
        <BankHeader
          highlightedMessage="Offer for you:"
          message="Changed Jobs Recently? It is time to make a decision about your 401(k)."
        />
        <div className="account-balance-container">
          <div className="account-balance-container--left">
            <BankAccountMenu
              items={[
                "Account",
                "Investment",
                "Documents",
                "Transfers",
                "Deposits",
                "Loans",
              ]}
              selectedItem="Account"
            />
          </div>

          <div className="account-balance-container--right">
            {!!suggestedProduct && (
              <>
                <div className="account-balance-detail-product">
                  <div className="account-balance-detail-product-container--horizontal">
                      <p className="account-balance-detail-product-detail">
                        Recommended For You:
                      </p>
                      <p className="account-balance-detail-product-title">
                        {suggestedProduct.label}
                      </p>
                  </div>
                  <div className="account-balance-detail-product-container--horizontal">
                    <button
                      className="account-balance-detail-product-action account-balance-detail-product-action--primary"
                      onClick={() => decideLearnMoreProduct()}
                    >
                      Learn More
                    </button>

                    <button
                      className="account-balance-detail-product-action account-balance-detail-product-action--secondary"
                      onClick={() => handleDismissProductSuggestion()}
                    >
                      Not for Me
                    </button>
                    
                  </div>

                </div>

                <div className="account-balance-detail-summary">
                  <BalanceCard
                    title="Checking"
                    detail="Account ending in 5678"
                    amount={balanceAmount}
                  />
                  <BalanceCard
                    title="Savings"
                    detail="Account ending in 2832"
                    amount={savingsAmount}
                  />
                  <BalanceCard
                    title="Loan Amount"
                    detail="Total Amount Owed"
                    amount={loanAmount}
                    color="yellow"
                  />
                </div>

                <div className="account-balance-detail-history">
                  <Card className="account-balance-detail-history-card">
                    <CardBody>
                      <div className="account-balance-detail-history-card-row">
                        <p className="account-balance-detail-history-card-title">
                          Transaction History
                        </p>
                        <p className="account-balance-detail-history-card-action">
                          View all
                        </p>
                      </div>
                      {lastThreeOperations?.map((operation, index) => (
                        <div
                          className="account-balance-detail-history-card-item"
                          key={`${operation.description}-${index}`}
                        >
                          <div className="account-balance-detail-history-card-item-row">
                            <p className="item-row-title">
                              {operation.description}
                            </p>
                            <p className="item-row-title">{operation.amount}</p>
                          </div>
                          <div className="account-balance-detail-history-card-item-row">
                            <p>{operation.date}</p>
                            <p>Account ending in 5678</p>
                          </div>
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                  <Card className="account-balance-detail-history-card">
                    <CardBody>
                      <div className="account-balance-detail-chart-card-row">
                        <p className="account-balance-detail-chart-card-title">
                          6 Month Change
                        </p>
                        <p className="account-balance-detail-chart-card-balance">
                          $
                          {parseFloat(
                            Number(savingsAmount) + Number(balanceAmount)
                          ).toFixed(2)}
                        </p>
                      </div>
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        className="account-balance-detail-chart-card"
                      >
                        <AreaChart
                          data={randomChartData}
                          margin={{ top: 20, right: 20, bottom: 0, left: 15 }}
                        >
                          <CartesianGrid
                            stroke="#000000"
                            strokeDasharray="3 0"
                            vertical={false}
                          />
                          <Tooltip />
                          <XAxis dataKey="name" />
                          <Area
                            type="monotone"
                            dataKey="balance"
                            stroke="#263A79"
                            fill="#263A79"
                          />
                        </AreaChart>
                      </ResponsiveContainer>

                      <RoundedSelector
                        selected="6M"
                        items={["1M", "3M", "6M", "YTD", "1Y"]}
                      />
                    </CardBody>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CodeFooter
        stemName={SAVVI_STEM.FEATURED_CREDIT_PRODUCT.name}
        decideSrc={decideData}
        resultsSrc={decisionResultData}
        decisionResults={suggestedProductResponse}
        displayResults={false}
      />
    </>
  );
});

export default BankAccountDetail;
