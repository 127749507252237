import { getPreviousDateByDays } from "./dates";
import { getRandomAmount } from "./random";

export const getRandomBankOperation = ({ isCredit = false }) => {
    const creditOperations = [
        'Deposit Employer',
        'Payroll Deposit',
        'Refund',
        'Transfer from Savings',
        'Interest paid',
        'Loan',
        'Credit Check',
        'Deposit',
        'Bank transfer - VENMO',
        'Mobile Check deposit',
    ];

    const debitOperation = [
        'Target',
        'Amazon',
        'Wallmart',
        'Telephone Bill Payment',
        'Transfer',
        'Account maintenance',
        'ATM Withdrawal',
        'Target Online',
    ]

    return isCredit
        ? creditOperations[Math.floor(Math.random() * creditOperations.length)]
        : debitOperation[Math.floor(Math.random() * debitOperation.length)];
};

export const getRandomLastOperations = (numberOfOperations = 3) => {
    return [...Array(numberOfOperations)].map((_, index) => {
        const isEven = index % 2 === 0;
        return {
          description: getRandomBankOperation({ isCredit: !isEven }),
          amount: getRandomAmount({
            min: isEven ? 500 : 10,
            max: isEven ? 2400 : 400,
            isNegative: isEven,
          }),
          date: getPreviousDateByDays(index * 2)
        }
    });
}

export const getParsedLoanOffers = ({ decisions = [], loanAmount = 0 }) => {
    return decisions.map((offer) => {
        const [months, percentage] = offer.id?.split('mo');
        return {
            ...offer,
            months,
            percentage,
            title: offer.label,
            amount: Math.floor((loanAmount / Number(months)) * ((100 + Number(percentage)) / 100)),
        }
    })
};

export const getNetLoanMargin = ({ loanInterestRate, autoPay, ficoScore }) => {
    return (loanInterestRate + (autoPay/100) - (0.03 + ((1 - ficoScore/825)/5)));
};
