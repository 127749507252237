import { toast } from 'react-toastify';

export const notifySuccess = (message, autoClose = 4000) => toast(
    <div>{message}</div>,
    { type: 'success', autoClose }
  );

export const notifyInfo = (message, autoClose = 2000) => toast(
    <div>{message}</div>,
    { type: 'dark', autoClose, progressStyle: { backgroundColor: 'lightgray' } }
);
