import BankAccountMenu from 'components/BankAccountMenu';
import BankHeader from 'components/BankHeader';
import CodeFooter from 'components/CodeFooter';
import { SAVVI_STEM } from 'config';
import LoanOfferCard from 'examples/fixed-term-loan/components/LoanOfferCard';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { getNetLoanMargin, getParsedLoanOffers } from 'utils/bank';
import { getRandomAmount, getRandomAmountMultipleOf, getRandomBoolean, getRandomName, getRandomPercentage } from 'utils/random';
import { notifyInfo, notifySuccess } from 'utils/ui';
import './BankLoanSection.styles.scss';

const BankLoanSection =  React.forwardRef(({}, ref) => {
  const [randomClientName, setRandomClientName] = useState(getRandomName());

  // Influencers
  const [accountBalance, setAccountBalance] = useState(getRandomAmount({ min: 250, max: 35000 }));
  const [ficoScore, setFicoScore] = useState(getRandomAmount({ min: 600, max: 850, decimals: 0 }));
  const [loanAmount, setLoanAmount] = useState(getRandomAmountMultipleOf({ min: 1000, max: 10000, isMultipleOf: 500 }));
  const [autoPaySetting, setAutoPaySetting] = useState(getRandomBoolean());
  const [hiddenCreditLineAmount, setHiddenCreditLineAmount] = useState(getRandomAmountMultipleOf({ min: 2000, max: 15000, isMultipleOf: 500 }));
  const [percentCreditLineOutstanding, setPercentCreditLineOutstanding] = useState(getRandomPercentage({ min: 1, max: 50 }));

  // Decision
  const [bestLoanOffers, setBestLoanOffers] = useState([]);
  const [bestLoanOffersResponse, setBestLoanOffersResponse] = useState({});
  const [bestLoanOfferDecision, setBestLoanOfferDecision] = useState(null);

  const [isLoadingOffers, setIsLoadingOffers] = useState(false);

  const updateWithRandomData = () => {
    setAccountBalance(getRandomAmount({ min: 250, max: 35000 }));
    setFicoScore(getRandomAmount({ min: 600, max: 850, decimals: 0 }));
    setLoanAmount(getRandomAmountMultipleOf({ min: 1000, max: 10000, isMultipleOf: 500 }));
    setAutoPaySetting(getRandomBoolean());
    setHiddenCreditLineAmount(getRandomAmountMultipleOf({ min: 2000, max: 15000, isMultipleOf: 500 }));
    setPercentCreditLineOutstanding(getRandomPercentage({ min: 1, max: 50 }));
    setBestLoanOffers([]);
    setBestLoanOffersResponse({});
    setBestLoanOfferDecision(null);
    setRandomClientName(getRandomName())
  }

  useImperativeHandle(ref, () => ({
    reloadDemoData() {
      updateWithRandomData();
    }
  }));

  const decideData = {
    fico: ficoScore,
    loanAmount: loanAmount,
    bankAccountBalance: accountBalance,
    preSetAutoPay: +autoPaySetting,
    hiddenCreditLineAmount: hiddenCreditLineAmount,
    percentCreditLineOutstanding: percentCreditLineOutstanding,
  };

  const loanOfferResults = {
    data: {
      LOANACCEPTANCE: 1,
      NETLOANMARGIN: getNetLoanMargin({
        loanInterestRate: parseFloat(bestLoanOfferDecision?.percentage / 100),
        autoPay: +autoPaySetting,
        ficoScore,
      }),
    },
    decisionUsedLabel: bestLoanOfferDecision?.label,
    decisionUsedId: bestLoanOfferDecision?.id,
    stemEventUpdateRule: "overwrite"
  }

  const handleGetOffersDecision = async () => {
    if (!!!window?.SAVVI_AI || !!!window?.SAVVI_AI[SAVVI_STEM.FIXED_TERM_LOAN.name]) {
      notifyInfo('We could not get an offer for you. Try again later.')
      return;
    }

    setIsLoadingOffers(true);
    const decisionResponse = await window?.SAVVI_AI[SAVVI_STEM.FIXED_TERM_LOAN.name].decide({
      data: decideData,
    });

    if (!!decisionResponse?.decision?.length) {
      setBestLoanOffersResponse(decisionResponse);
      setBestLoanOffers(getParsedLoanOffers({ decisions: decisionResponse.decision, loanAmount }));
    } else {
      notifyInfo("We could not get loan offers for you. Try again!" )
      setBestLoanOffers([]);
      setBestLoanOffersResponse({});
    }
    setIsLoadingOffers(false);
  }

  const decideAcceptLoan = async (decisionIndex) => {
    setBestLoanOfferDecision(bestLoanOffers[decisionIndex]);
    const decisionBody = {
      data: {
        LOANACCEPTANCE: 1,
        NETLOANMARGIN: getNetLoanMargin({
          loanInterestRate: parseFloat(bestLoanOffers[decisionIndex]?.percentage / 100),
          autoPay: +autoPaySetting,
          ficoScore,
        }),
      },
      decisionUsedLabel: bestLoanOffers[decisionIndex]?.label,
      decisionUsedId: bestLoanOffers[decisionIndex]?.id,
      stemEventUpdateRule: "overwrite"
    }
    const loanOfferResponse = await window?.SAVVI_AI[SAVVI_STEM.FIXED_TERM_LOAN.name].results(decisionBody);

    if (loanOfferResponse?.success) {
      notifySuccess(`Thanks for accepting a loan of $${loanAmount} (${bestLoanOffers[decisionIndex]?.label}).`);
    }
  }

  useEffect(() => {
    handleGetOffersDecision();
  }, [loanAmount]);
  
  return (
    <>
      <div className="bank-loan-container">
          <BankHeader highlightedMessage={`Congratulations, ${randomClientName}! You are pre-qualified for a business loan.`} />
          <div className='bank-loan-account-container'>
            <div className='bank-loan-account-container--left'>
            <BankAccountMenu 
              items={["Account", "Investment", "Documents", "Transfers", "Deposits", "Loans"]}
              selectedItem="Loans"
            />
            </div>

            <div className='bank-loan-account-container--right'>
                {!isLoadingOffers && (<>
                  <div className='bank-loan-offer'>
                    <p className='bank-loan-offer-text'>Select the Loan Recommended for You:</p>
                    <p className='bank-loan-offer-amount'>Loan Amount: <strong>${loanAmount}</strong></p>
                  </div>
                  <div className='bank-loan-cards-container'>
                    {!!!bestLoanOffers.length
                      ? <>
                        <p className='bank-loan-cards-container-loader'>
                          There are no loan offers. Try again.
                        </p>
                      </>
                      : <>
                        {bestLoanOffers?.slice(0,3).map((offer, index) => (
                          <LoanOfferCard
                            title={offer?.title}
                            amount={offer?.amount}
                            buttonAction={() => decideAcceptLoan(index)}
                          />
                        ))}
                      </>
                    }              
                  </div>

                  <div className='bank-loan-account-info-card-container'>
                    <div className='bank-loan-account-info-card'>
                        <div>
                          <p className='bank-loan-account-info-card-title'>Account Balance</p>
                          <p className='bank-loan-account-info-card-info'>Account ending *5678</p>
                        </div>
                        <p className='bank-loan-account-info-card-data'>${accountBalance}</p>
                    </div>
                  </div>
                </>)
                }
            </div>
          </div>
      </div>
      <CodeFooter
        stemName={SAVVI_STEM.FIXED_TERM_LOAN.name}
        decideSrc={decideData}
        resultsSrc={!!bestLoanOfferDecision ? loanOfferResults : {}}
        decisionResults={bestLoanOffersResponse}
        displayResults={false}
      />
    </>
  );
});

export default BankLoanSection;

