import React from "react";
import "./RoundedSelector.styles.scss";

const RoundedSelector = ({ selected = "", items = [] }) => {
  return (
    <div className="rounded-selector-container">
      {items.map((item) => {
        return (
          <div
            key={item}
            className={`rounded-selector-item ${
              selected === item ? "rounded-selector-item--selected" : ""
            }`}
          >
            <p className="rounded-selector-item-text">{item}</p>
          </div>
        );
      })}
    </div>
  );
};

export default RoundedSelector;
