import ExampleContainer from 'components/ExampleContainer';
import React from 'react';
import ContentBody from './sections/ContentBody';

const IceCreamPicker = () => (
  <ExampleContainer >
    <ContentBody />
  </ExampleContainer>
);

export default IceCreamPicker;
