import React from 'react';
import ReactDOM from 'react-dom/client';
import ExamplesApp from './ExamplesApp';
import IceCreamPicker from 'examples/ice-cream-picker';
import FixedTermLoan from 'examples/fixed-term-loan';
import FeaturedCreditProduct from 'examples/featured-credit-product';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ExamplesApp />} />
        <Route path="ice-cream-picker" element={<IceCreamPicker />} />
        <Route path="fixed-term-loan" element={<FixedTermLoan />} />
        <Route path="featured-credit-product" element={<FeaturedCreditProduct />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

