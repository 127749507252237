import { SELECTABLE_COLORS } from "constants/ui";
import Fakerator from 'fakerator';

export const getRandomBoolean = () => {
    return Math.random() < 0.5;
};

export const getRandomColor = () => {
    const randomValue = Math.random() * 100;

    switch (true) {
        case (randomValue < 20):
            return SELECTABLE_COLORS.BLACK;

        case (randomValue < 40):
            return SELECTABLE_COLORS.BLUE;

        case (randomValue < 60):
            return SELECTABLE_COLORS.GREEN;

        case (randomValue < 80):
            return SELECTABLE_COLORS.PINK;
        
        default:
            return SELECTABLE_COLORS.RED;
    }
};

export const getRandomAmount = ({ min = 0, max = 10000, isNegative = false, decimals = 2 }) => {
    const amount = parseFloat((Math.random() * (max - min) + min)).toFixed(decimals);
    return isNegative ? amount * -1 : amount;
};

export const getRandomAmountMultipleOf = ({ min = 100, max = 100000, isMultipleOf = 100 }) => {
    const amount = getRandomAmount({ min, max, isNegative: false, decimals: 0});
    const multipleRest = amount % isMultipleOf;
    return amount - multipleRest;
};

export const getRandomName = () => {
  const randomGenerator = Fakerator('en-US');
  return randomGenerator?.names?.name() || 'Jhon Smith';
};

export const getRandomFourDigits = () => {
    return Math.floor(1000 + Math.random() * 9000);
};

export const getRandomPercentage = ({ min = 0, max = 100 }) => {
    return parseFloat(getRandomAmount({ min, max}) / 100).toFixed(2);
};
