import { SELECTABLE_COLORS } from 'constants/ui';
import React from 'react';
import Switch from "react-bootstrap-switch";
import { Input, Label, FormGroup } from 'reactstrap';
import './InfluencerItem.styles.scss';

const InfluencerItem = ({
  name,
  type = 'boolean',
  value = null,
  defaultBooleanValue = false,
  defaultOptionValue = false,
  handleBooleanInfluencerChange = () => {},
  handleMultipleOptionInfluencerChange = () => {},
}) => {

  const ColorSelector = () => (<FormGroup check className="form-check-radio">
    {Object.values(SELECTABLE_COLORS)?.map((selectableColor) => {
      const isSelected = value === selectableColor;
      return (
      <Label check className={`radio-selector-label influencer-item-selector-label ${isSelected ? 'influencer-item-selector-label--selected' : ''}`} key={selectableColor}>
        <Input
          defaultChecked={selectableColor === defaultOptionValue}
          defaultValue={selectableColor}
          id={selectableColor}
          name="favorite-color"
          type="radio"
          onClick={() => handleMultipleOptionInfluencerChange(selectableColor)}
        ></Input>
        <div className='influencer-item-selector-label-container'>
          <div className={`influencer-item-selector-label-indicator influencer-item-selector-label-indicator--${selectableColor}`} />
          {`${selectableColor.charAt(0).toUpperCase()}${selectableColor.slice(1)}`}
        </div>
      </Label>
    )})}
    </FormGroup>
  );

  const selectorElement = () => {
    switch (type) {
      case 'boolean':
        return (
          <Switch
            className="influencer-item-switch"
            offText="No"
            onText="Yes"
            value={value}
            defaultValue={defaultBooleanValue}
            onChange={(_, value) => handleBooleanInfluencerChange(value)}
          />
        )

      case 'multiple':
        return <ColorSelector />
      
      default:
        return <Switch offText="No" onText="Yes" />
    }
  }
  return (
    <div>
        <p className="influencer-item-name">{name}</p>
        {selectorElement()}
    </div>

  );
}

export default InfluencerItem;
