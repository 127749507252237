import CodeFooter from 'components/CodeFooter';
import { SAVVI_STEM } from 'config';
import IceCream from 'examples/ice-cream-picker/components/IceCream';
import InfluencerItem from 'examples/ice-cream-picker/components/InfluencerItem';
import React, { useImperativeHandle, useState } from 'react';
import { Button, Pagination, PaginationItem, PaginationLink, UncontrolledTooltip } from 'reactstrap';
import { getRandomBoolean, getRandomColor } from 'utils/random';
import { notifyInfo, notifySuccess } from 'utils/ui';
import './ContentBody.styles.scss';

const ContentBody = React.forwardRef((props, ref) => {
  const [isLoadingDecision, setIsLoadingDecision] = useState(false);

  // Influencers
  const [likeTart, setLikeTart] = useState(getRandomBoolean());
  const [likeSweet, setLikeSweet] = useState(getRandomBoolean());
  const [hasNutAllergy, setHasNutAllergy] = useState(getRandomBoolean());
  const [favoriteColor, setFavoriteColor] = useState(getRandomColor());

  // Decision
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [iceCreamDecision, setIceCreamDecision] = useState(null);
  const [iceCreamDecisionResponse, setIceCreamDecisionResponse] = useState(null);
  const [iceCreamResultsData, setIceCreamResultsData] = useState({});

  const iceCreamDecideData = {
    LikeTart: +likeTart,
    LikeSweet: +likeSweet,
    hasNutAllergy: +hasNutAllergy,
    FavoriteColor: favoriteColor,
  }

  useImperativeHandle(ref, () => ({
    reloadDemoData() {
      handleGetRandomInfluencers();
    }
  }));

  const handleIceCreamCancelOrder = async () => {
    const iceCreamResultsData = {
      data: {
        AmountOrdered: 0,
      },
      decisionUsedLabel: iceCreamDecision?.label,
      decisionUsedId: iceCreamDecision?.id,
      stemEventUpdateRule: "overwrite"
    };
    setIceCreamResultsData(iceCreamResultsData);
    await window?.SAVVI_AI[SAVVI_STEM.ICE_CREAM_PICKER.name].results(iceCreamResultsData)
      .then(() => {
        notifySuccess(`Your order was successfully canceled.`);
    });
  }

  const handleGetRandomInfluencers = () => {
    setLikeTart(getRandomBoolean());
    setLikeSweet(getRandomBoolean());
    setHasNutAllergy(getRandomBoolean());
    setFavoriteColor(getRandomColor());
    setIceCreamDecisionResponse(null);
    setSelectedAmount(1);
    setIceCreamDecision(null);
    setIceCreamDecisionResponse(null);
    setIceCreamResultsData({});
  }

  const handleGetDecision = async () => {
    if (!!!window?.SAVVI_AI || !!!window?.SAVVI_AI[SAVVI_STEM.ICE_CREAM_PICKER.name]) {
      notifyInfo('We could not get your favorite ice cream. Try again later.')
      return;
    }

    if (!!iceCreamDecision) {
      handleIceCreamCancelOrder();
      return;
    };
    
    setIsLoadingDecision(true);
    const decisionResponse = await window?.SAVVI_AI[SAVVI_STEM.ICE_CREAM_PICKER.name].decide({
      data: iceCreamDecideData,
    });

    if (!!decisionResponse?.decision?.length) {
      setIceCreamDecision(decisionResponse?.decision[0]);
      setIceCreamDecisionResponse(decisionResponse);
    } else {
      notifyInfo("We could not get your favorite ice cream. Try again!" )
      setIceCreamDecision(null);
      setIceCreamDecisionResponse(null);
    }
    setIsLoadingDecision(false);
  }

  const handleIceCreamOrder = async () => {
    const iceCreamResults = {
      data: {
        AmountOrdered: selectedAmount,
      },
      decisionUsedLabel: iceCreamDecision?.label,
      decisionUsedId: iceCreamDecision?.id,
      stemEventUpdateRule: "overwrite"
    }
    setIceCreamResultsData(iceCreamResults);
    const orderResponse = await window?.SAVVI_AI[SAVVI_STEM.ICE_CREAM_PICKER.name].results(iceCreamResults);

    if (orderResponse?.success) {
      notifySuccess(`Your order of ${selectedAmount} ice creams (${iceCreamDecision?.label}) was sent.`);
    }
  }

  return (
    <>
      <div className="body-content-container">
          <div className="container-left">
            <InfluencerItem
              name="Do you like tart?"
              type="boolean"
              defaultBooleanValue={likeTart}
              value={likeTart}
              handleBooleanInfluencerChange={(value) => setLikeTart(value)}
            />
            <InfluencerItem
              name="Do you like sweet?"
              type="boolean"
              defaultBooleanValue={likeSweet}
              value={likeSweet}
              handleBooleanInfluencerChange={(value) => setLikeSweet(value)}
            />
            <InfluencerItem
              name="Do you have a nut allergy?"
              type="boolean"
              value={hasNutAllergy}
              defaultBooleanValue={hasNutAllergy}
              handleBooleanInfluencerChange={(value) => setHasNutAllergy(value)}
            />
            <InfluencerItem
              name="What is your favorite color? (Pick One)"
              type="multiple"
              value={favoriteColor}
              defaultOptionValue={favoriteColor}
              handleMultipleOptionInfluencerChange={(value) => setFavoriteColor(value)}
            />
          </div>
          {!!!iceCreamResultsData?.data && !!!iceCreamDecision &&(
            <button
              className='actionable-button'
              id="tooltip-get-decision"
              onClick={handleGetDecision}
            >
              {isLoadingDecision
                ? <i className="now-ui-icons loader_refresh spin loading-decision-icon" />
                : <i className="now-ui-icons media-1_button-play loading-decision-icon"  />
              }
            </button>
          )}
          <UncontrolledTooltip
            delay={0}
            placement="right"
            target="tooltip-get-decision"
            >
              {isLoadingDecision
                ? 'We are getting your favorite ice cream.'
                : 'Get my favorite ice cream!'
              }
          </UncontrolledTooltip>
          <div className="container-right">
            <div className="ice-cream-order-container">
                <IceCream flavor={iceCreamDecision?.id} name={iceCreamDecision?.label}/>
                {iceCreamDecision && !!!iceCreamResultsData.data && (
                  <div className="order-cart-container">
                    <i className="now-ui-icons shopping_cart-simple"  />
                    <p className="order-title">How many do you want to order?</p>
                    <Pagination
                      className="ice-cream-order-selector"
                      listClassName="pagination-info"
                    >
                      {[...Array(5).keys()].map((amount) => (
                        <PaginationItem className={selectedAmount === amount + 1 ? 'active' : ''}>
                          <PaginationLink onClick={() => setSelectedAmount(amount + 1)}>
                            {amount + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                    </Pagination>
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      onClick={handleIceCreamOrder}
                    >
                      <i className="now-ui-icons ui-1_check button-icon"></i>
                      Order
                    </Button>
                    <Button className="btn-round" color="neutral" type="button" size="sm" onClick={handleIceCreamCancelOrder}>
                      <i className="now-ui-icons ui-1_simple-remove button-icon"></i>
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
          </div>
      </div>
      <CodeFooter
        stemName={SAVVI_STEM.ICE_CREAM_PICKER.name}
        decideSrc={iceCreamDecideData}
        decisionResults={iceCreamDecisionResponse || {}}
        resultsSrc={iceCreamResultsData}
        displayResults={!!!iceCreamDecision}
      />
    </>

  );
})

export default ContentBody;
