import React from 'react';
import './LoanOfferCard.styles.scss';

const LoanOfferCard = ({
    title = '',
    amount = '',
    detail = 'Monthly Payments:',
    buttonText = 'Select Loan',
    buttonAction = () => {},
}) => {
  return (
    <div className='loan-offer-card'>
      <h3 className='loan-offer-card-content-title'>{title}</h3>
      <div className='loan-offer-card-content'>
        <>
        </>
        <p className='loan-offer-card-content-text'>{detail} <strong>${amount}</strong></p>
        <button className='loan-offer-card-button' onClick={buttonAction}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default LoanOfferCard;
