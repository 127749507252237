import React from "react";
import BankAccountBalanceOption from "./subcomponents/BankAccountBalanceOption";
import './BankAccountMenu.styles.scss';
import Switch from "react-bootstrap-switch";

const BankAccountMenu = ({
    items = [],
    selectedItem = null,
    settings = [],
}) => {
  return (
      <>
        <div className='account-menu-options-container'>
            {items.map((item) => (
                <BankAccountBalanceOption
                    key={item}
                    name={item}
                    isSelected={item === selectedItem}
                />
            ))}
            <div className='account-menu-options-settings'>
                {!!settings.length && (
                    <div>
                        <h3 className='account-menu-options-settings-title'>Settings</h3>
                        <i className="now-ui-icons loader_gear account-menu-options-settings-icon" />
                    </div>
                )}
                {settings.map((setting) => (
                    <div>
                        <p className='account-menu-options-settings-name'>{setting.name}</p>
                        <Switch
                            offText="No"
                            onText="Yes"
                            value={setting.value}
                            defaultValue={false}
                            onChange={(_, value) => setting.handleValueChange(value)}
                        />
                    </div>
                    
                ))}
            </div>
        </div>
      </>
  );
}

export default BankAccountMenu;
